<template>
    <div class="container">
        <BreadcrumbNav :page-name="$t('vipRecommend.recommendReason')"/>
        <div class="main">
            <img :src="coverUrl" alt="" class="show-image">
            <div class="project-name-key">{{ $t('vipRecommend.projectName') }}</div>
            <div class="project-name-value">{{ title}}</div>
            <!--<div class="reason">-->
            <!--  &lt;!&ndash; 翻译：推荐原因&ndash;&gt;-->
            <!--  <h1>{{ $t('vipRecommend.reasonsForRecommendation') }}</h1>-->
            <!--  <p class="over4">{{ content.introduce_project_reason }}</p>-->
            <!--</div>-->
            <div class="content">
                <div class="title">{{$t("publish.content")}}</div>
                <div v-html="content"></div>
            </div>
        </div>

    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import RichText from "@/components/richText";
    import {introduceTemplate} from "@/api/projectAbout";

    export default {
        name: "RecommendReason",
        data() {
            return {
                coverUrl: "",
                title: "",
                content: "",
            };
        },
        components: {
            BreadcrumbNav,
            RichText,
        },
        props: {},
        mounted() {
            this.coverUrl = this.$route.query.coverUrl;
            this.title = this.$route.query.title;
            this.content = this.$route.query.content;
        },
    }
</script>

<style scoped>
    .content ::v-deep.ql-video {
        width: 650px;
        height: 325px;
    }

    .container {
        background-color: #FFFFFF;
    }

    .main {
        width: 1200px;
        margin: 0 auto;
        padding: 71px 0 53px;
    }

    .show-image {
        width: 695px;
        height: 460px;
        background: rgba(0, 0, 0, 0);
        border-radius: 4px;
    }

    .project-name-key {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
        margin-top: 47px;
    }

    .project-name-value {
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 46px;
        color: #000000;
        margin-top: 14px;
    }

    .reason {
        margin-top: 53px;
    }

    .reason > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .reason > p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
        margin-top: 10px;
    }

    .content {
        margin-top: 32px;
    }

    .content > .title {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #000000;
        margin-bottom: 30px;
    }

    .content > .tip {
        margin-top: 14px;
        margin-bottom: 18px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 24px;
        color: #949494;
    }
</style>
